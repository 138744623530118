export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 45">
      <g fill={fillColor}>
        <path d="M36.892 42.335c-.262.258-.608.387-1.039.387-.391.0-.651.0-.78.0.0.939.0 1.565.0 1.879-.214.0-.357.0-.429.0.0-1.692.0-2.822.0-3.39.0-.564.0-.941.0-1.13.512.0.894.0 1.148.0.483.0.854.125 1.112.375.254.246.381.556.381.931S37.154 42.077 36.892 42.335zM36.566 40.716c-.185-.157-.465-.236-.84-.236-.326.0-.544.0-.653.0.0.923.0 1.537.0 1.843.326.0.57.0.731.0.346.0.606-.089.78-.266.169-.177.254-.401.254-.671C36.838 41.1 36.747 40.877 36.566 40.716z" />
        <path d="M41.353 44.601h-.157c-.105.0-.211.0-.32.0l-.054-.133c-.04-.089-.085-.189-.133-.302-.024-.06-.05-.121-.079-.181-.024-.06-.046-.115-.066-.163-.004-.008-.008-.016-.012-.024-.004-.008-.006-.016-.006-.024h-.369c-.242.0-.518.0-.828.0-.165.0-.33.0-.496.0-.169.0-.32.0-.453.0-.024.0-.046.0-.066.0-.016.0-.034.0-.054.0l-.054.139c-.04.089-.085.189-.133.302-.024.06-.048.121-.073.181-.028.056-.052.111-.073.163-.004.008-.006.016-.006.024-.004.004-.008.01-.012.018h-.073c-.024.0-.052.0-.085.0-.105.0-.209.0-.314.0l.139-.326c.097-.218.218-.496.363-.834.081-.181.165-.377.254-.586.089-.205.179-.417.272-.634.145-.33.29-.661.435-.991.141-.33.272-.632.393-.906.016-.04.032-.081.048-.121.02-.04.038-.081.054-.121l.139.326c.097.218.218.498.363.84.081.181.165.375.254.58.089.209.181.421.278.634.141.334.284.667.429.997.141.33.272.632.393.906.016.04.032.081.048.121C41.319 44.527 41.337 44.565 41.353 44.601zM40.362 43.399l-.157-.381c-.105-.25-.224-.534-.357-.852-.068-.169-.139-.34-.211-.514-.068-.169-.133-.324-.193-.465-.008-.02-.016-.04-.024-.06-.008-.02-.016-.04-.024-.06l-.157.375c-.105.254-.224.538-.357.852-.073.173-.145.344-.218.514-.068.173-.133.328-.193.465-.008.024-.016.046-.024.066-.008.02-.016.04-.024.06h.284c.008.0.016.0.024.0.209.0.447.0.713.0.141.0.282.0.423.0.145.0.276.0.393.0.016.0.032.0.048.0C40.328 43.399 40.346 43.399 40.362 43.399z" />
        <path d="M43.228 40.48c-.105.0-.222.0-.35.0-.068.0-.135.0-.199.0-.068.0-.127.0-.175.0v.091c0 .06.0.145.0.254.0.197.0.461.0.792.0.326.0.673.0 1.039.0.302.0.604.0.906.0.298.0.572.0.822.0.04.0.079.0.115.0.036.0.07.0.103h-.145c-.097.0-.191.0-.284.0v-.326c0-.153.0-.334.0-.544.0-.093.0-.191.0-.296.0-.181.0-.375.0-.58.0-.209.0-.421.0-.634.0-.334.0-.667.0-.997.0-.33.0-.63.0-.9.0-.044.0-.087.0-.127.0-.036.0-.075.0-.115h.06c.04.0.183.0.429.0.105.0.234.0.387.0s.336.0.55.0c.395.0.719.121.973.363.258.242.387.552.387.931.0.334-.123.624-.369.87-.246.246-.538.369-.876.369h-.079c-.052.0-.105.0-.157.0l.284.32c.189.214.401.455.634.725.129.149.258.296.387.441.125.145.24.278.344.399.016.016.032.034.048.054.016.016.032.032.048.048h-.079c-.028.0-.064.0-.109.0-.121.0-.242.0-.363.0l-.042-.048c-.028-.036-.068-.083-.121-.139-.097-.113-.222-.26-.375-.441-.157-.181-.322-.373-.496-.574-.145-.165-.29-.332-.435-.502-.145-.165-.276-.316-.393-.453-.02-.02-.038-.04-.054-.06-.016-.02-.032-.04-.048-.06h.03c.024.0.101.0.23.0.052.0.119.0.199.0s.179.0.296.0c.314.0.568-.085.761-.254.193-.165.292-.393.296-.683.0-.173-.028-.316-.085-.429-.056-.113-.127-.201-.211-.266-.149-.125-.31-.191-.483-.199l-.26-.012H43.228z" />
        <path d="M48.57 44.601c-.906-1.124-1.511-1.873-1.813-2.248-.133.141-.222.234-.266.278.0.987.0 1.644.0 1.97-.214.0-.357.0-.429.0.0-1.692.0-2.822.0-3.39.0-.564.0-.941.0-1.13.214.0.357.0.429.0.0.999.0 1.664.0 1.994.923-.995 1.539-1.66 1.849-1.994.27.0.451.0.544.0-.919.983-1.531 1.638-1.837 1.964 1.031 1.277 1.72 2.129 2.067 2.556C48.84 44.601 48.659 44.601 48.57 44.601z" />
        <path d="M54.501 44.42c-.29.169-.639.254-1.045.254-.379.0-.733-.095-1.064-.284-.33-.189-.594-.449-.792-.78-.109-.177-.193-.373-.254-.586-.06-.214-.091-.441-.091-.683.0-.407.093-.786.278-1.136.185-.35.435-.63.749-.84.169-.113.352-.199.55-.26.201-.064.413-.097.634-.097.367.0.687.064.961.193.27.125.496.298.677.52-.141.125-.246.218-.314.278-.165-.181-.359-.322-.58-.423-.218-.101-.465-.151-.743-.151-.326.0-.62.083-.882.248-.262.161-.469.377-.622.647-.081.149-.143.308-.187.477-.044.169-.066.344-.066.526.0.383.081.717.242 1.003.161.286.367.51.616.671.133.085.276.149.429.193.153.048.31.073.471.073.286.0.544-.058.773-.175.234-.113.447-.278.641-.496.157.129.26.216.308.26C55.025 44.061 54.795 44.251 54.501 44.42z" />
        <path d="M56.23 44.601c0-1.692.0-2.822.0-3.39.0-.564.0-.941.0-1.13.214.0.357.0.429.0.0 2.26.0 3.767.0 4.52C56.446 44.601 56.303 44.601 56.23 44.601z" />
        <path d="M59.138 40.48c0 2.063.0 3.436.0 4.121-.218.0-.363.0-.435.0.0-2.059.0-3.432.0-4.121-.524.0-.872.0-1.045.0.0-.149.0-.248.0-.296s0-.083.0-.103c1.269.0 2.115.0 2.538.0.0.201.0.334.0.399C59.664 40.48 59.312 40.48 59.138 40.48z" />
        <path d="M62.879 42.474c0 1.064.0 1.773.0 2.127-.214.0-.357.0-.429.0.0-1.068.0-1.779.0-2.133-.798-1.192-1.327-1.988-1.589-2.387.25.0.417.0.502.0.661.987 1.102 1.644 1.323 1.97.657-.983 1.096-1.64 1.317-1.97.242.0.405.0.489.0C63.687 41.278 63.149 42.075 62.879 42.474z" />
      </g>
      <g>
        <g>
          <path d="M99.13 29.628c-.41.0-.744-.322-.744-.718.0-.397.334-.719.744-.719.411.0.746.322.746.719C99.875 29.306 99.541 29.628 99.13 29.628m0-1.561c-.479.0-.869.377-.869.841.0.463.39.84.869.84.481.0.87-.378.87-.84C1e2 28.444 99.61 28.067 99.13 28.067" />
          <path d="M98.975 28.852v-.317h.18c.1.0.167.081.167.148.0.108-.076.169-.185.169H98.975zM99.19 28.966c.15.0.287-.124.287-.279.0-.158-.125-.288-.312-.288h-.339v.976h.149V28.93l.398.445h.189L99.19 28.966z" />
        </g>
        <path d="M57.221 33.778l1.69-4.047 1.679 4.047H57.221zM58.91 28.026l-3.4 7.844h.823l.606-1.433h3.931l.617 1.433h.823L58.91 28.026z" />
        <path d="M64.932 35.996c1.159.0 2.068-.837 2.068-2.04.0-1.014-.65-1.663-1.711-2.269-.888-.502-1.494-.805-1.494-1.715.0-.753.541-1.192 1.137-1.192.52.0.953.188 1.418.586l.466-.533c-.52-.481-1.148-.721-1.895-.721-1.082.0-1.895.763-1.895 1.892.0 1.099.747 1.684 1.819 2.29.931.523 1.375.899 1.375 1.705.0.795-.487 1.317-1.266 1.317-.693.0-1.138-.23-1.604-.627l-.455.523C63.48 35.734 64.087 35.996 64.932 35.996" />
        <path d="M72.522 28.904V28.235H68.386V28.904H70.075V35.87H70.833V28.904z" />
        <path d="M76.416 35.305c-1.798.0-3.27-1.463-3.27-3.263.0-1.904 1.321-3.221 3.227-3.221 1.916.0 3.292 1.35 3.292 3.19C79.665 34.176 78.04 35.305 76.416 35.305M76.362 28.099c-2.284.0-4.017 1.694-4.017 3.943.0 2.259 1.754 3.964 4.072 3.964 2.274.0 4.05-1.726 4.05-3.943C80.466 29.773 78.744 28.099 76.362 28.099" />
        <path d="M86.412 30.358c0 1.004-.704 1.663-1.808 1.663h-1.332c.13.126 3.422 3.807 3.454 3.849h.942c-.173-.188-3.043-3.358-3.043-3.358h.412c1.18.0 2.176-.962 2.176-2.102.0-1.266-.996-2.176-2.383-2.176H82.34v7.635h.748v-6.966h1.548C85.86 28.905 86.412 29.751 86.412 30.358" />
        <path d="M89.872 28.235h.747v7.635H89.872z" />
        <path d="M93.781 33.778l1.689-4.047 1.679 4.047H93.781zM95.47 28.026l-3.4 7.844h.823l.606-1.433h3.931l.617 1.433h.823L95.47 28.026z" />
        <path d="M9.702 28.235 7.31 34.28 5.252 29.542 3.238 34.302.823 28.235H0L3.184 36.08 5.263 31.226 7.342 36.08 10.526 28.235z" />
        <path d="M11.956 33.778l1.689-4.047 1.679 4.047H11.956zM13.645 28.026l-3.4 7.844h.823l.606-1.433h3.931l.617 1.433h.823L13.645 28.026z" />
        <path d="M22.374 35.87V35.201H19.179V28.235H18.432V35.87z" />
        <path d="M25.763 35.222h-1.234v-6.338h1.332c1.928.0 3.303 1.276 3.303 3.128C29.164 33.799 27.897 35.222 25.763 35.222M25.796 28.235h-2.014v7.635h1.97c2.48.0 4.202-1.569 4.202-3.87C29.955 29.721 28.254 28.235 25.796 28.235" />
        <path d="M35.543 35.305c-1.798.0-3.27-1.463-3.27-3.263.0-1.904 1.321-3.221 3.227-3.221 1.917.0 3.292 1.35 3.292 3.19C38.792 34.176 37.167 35.305 35.543 35.305M35.489 28.099c-2.285.0-4.018 1.694-4.018 3.943.0 2.259 1.755 3.964 4.072 3.964 2.274.0 4.051-1.726 4.051-3.943C39.593 29.773 37.871 28.099 35.489 28.099" />
        <path d="M45.539 30.358c0 1.004-.704 1.663-1.808 1.663h-1.332c.13.126 3.422 3.807 3.454 3.849h.942c-.173-.188-3.042-3.358-3.042-3.358h.411c1.18.0 2.176-.962 2.176-2.102.0-1.266-.996-2.176-2.382-2.176h-2.49v7.635h.747v-6.966h1.549C44.987 28.905 45.539 29.751 45.539 30.358" />
        <path d="M52.577 28.906V28.237H48.348V35.863H49.095V32.541H51.766V31.873H49.095V28.906z" />
        <path d="M52.817 2.012l.332-.678 8.812 18.42h-.637L52.817 2.012zM36.21.322h.637l7.93 16.539-.334.67L36.21.322zM44.101.322h.638l7.93 16.539-.334.67L44.101.322zM41.555.0v.322h.822l5.215 10.884-2.531 5.083L37.531.322h.676V0h-4.622v.322h.823l9.615 19.699 3.985-8.003 3.906 8.003 3.972-7.978 3.636 7.71h-.676v.323h4.622v-.323h-.823L53.023.041l-3.537 7.238.376.782 2.11-4.318 2.589 5.489h-4.136l.28.581h4.131l.258.547h-4.127l.277.576h4.121l.123.263-2.534 5.091L45.423.322h.755V0H41.555zM58.84.0v.322h2.034l-3.49 7.133.383.783 3.859-7.917h1.837V0H58.84z" />
      </g>
    </svg>
  );
}
